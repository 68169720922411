
import React from 'react'
import Hero from '../Elements/Hero'
import tiktok from '../VedioHero/tiktok.svg'
import twitch from '../VedioHero/19.png'
import youtube from '../VedioHero/youtube.png'
import CenterElement from '../Elements/CenterElement';
import Featured from '../Elements/Featured';
import Download from '../Elements/Download';
import ProfitHoder from '../Elements/ProfitHoder';
import ContactUs from './Contact-Us';
import VedioHeader from '../VedioHero';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd'
import BeemiLive from '../BeemiLive'
import axios from 'axios';
import { load } from 'cheerio'

async function fetchIpAddress() {
    try {
        const response = await axios.get('https://ipinfo.io/what-is-my-ip');
        const html = response.data;
        const $ = load(html);
        const ipAddress = $('.h1-max').text();
        console.log('IP address:', html.country);

        return html;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;
    }
}


export default function Home() {
    const { t, i18n } = useTranslation();
    const userLanguage = navigator.language || navigator.userLanguage;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); // Switch language
    };
    React.useEffect(() => {
        console.log("language is ", i18n.language);

        if (userLanguage.startsWith("am")) {
            i18n.changeLanguage('am')
        } else if (userLanguage.startsWith("en")) {
            i18n.changeLanguage('en')
        } else {
            console.log("Defaulting to English");
        }
    }, [])
    return (
        <div className='w-[100vw] !xl:max-w-[1800px] text-center overflow-x-hidden scroll-smooth'>
            <Hero />
            <ProfitHoder />
            <CenterElement />
            <Tabs
                id='how'
                className='px-[5%] flex items-center'
                tabBarStyle={{ color: 'red' }}
                items={[
                    {
                        title: t('tiktok'),
                        body: t('tiktokbody'),
                        icon: tiktok,
                        vedio: t('vediotik'),
                        link: 'vediotik'
                    }, {
                        title: t('twich'),
                        body: t('twichbody'),
                        icon: twitch,
                        vedio: t('vediotwi'),
                        link: 'vediotwi'

                    }, {
                        title: t('youtube'),
                        body: t('youtubeBody'),
                        icon: youtube,
                        vedio: t('vedioyou'),
                        link: 'vedioyou'

                    }].map((item, i) => {
                        return {
                            label: <img src={item.icon} className='w-10 md:w-20' />,
                            key: item.title,
                            children: <VedioHeader key={i} {...item} />,
                        };
                    })}
            />
            {/* <div className='pt-10 md:pt-0'>
                <BeemiLive />
            </div> */}
            <Featured />
            <Download id='download' />
            <ContactUs />
        </div>
    )
}
