import React from 'react'
import { GooglePlayButton, AppStoreButton, ButtonsContainer } from "react-mobile-app-button";

import { message, Spin } from 'antd';
import { firestoreDb } from "../../firebase";
import {
    collection,
    doc,
    getDocs,
} from "firebase/firestore";
import { useTranslation } from 'react-i18next';

export default function Download() {
    const { t } = useTranslation();

    const [messageApi, contextHolder] = message.useMessage();
    let android = React.useRef()
    let ios = React.useRef()
    let windowsRef = React.useRef()
    const [data, setData] = React.useState(null)

    async function fetchAllDocuments() {
        const collectionRef = collection(firestoreDb, "apk_link");
        try {
            const querySnapshot = await getDocs(collectionRef);
            const documents = querySnapshot.docs.map(doc => {
                if (doc.id == 'android') {
                    android.current = doc.data().active
                }
                if (doc.id == 'ios') {
                    ios.current = doc.data().active
                }
                if (doc.id == 'window') {
                    windowsRef.current = doc.data().active
                }
            });
            setData(documents)

            return documents;  // Returns an array of documents
        } catch (error) {
            console.error("Error fetching documents:", error);
            return [];  // Return an empty array or handle the error as needed
        }
    }

    React.useEffect(() => {
        fetchAllDocuments()
    }, [])

    const userEventsListenersGTAG = ({ action, category, label, value }) => {
        window.gtag("event", action, {
            event_category: category,
            event_label: label,
            value: value,
        });
    };



    return (
        <div id='download' className='bg-white overflow-hidden'>
            <div className="join-section-text">
                <div className="center-text">
                    <div className='text-[#8D8D8D] font-[Montserrat-Bold] text-2xl' style={{ color: "#8D8D8D" }}>
                        {t('downloadHere')}
                    </div>
                </div>
            </div>
            {contextHolder}
            {data ?
                <div className="flex flex-col md:flex-row items-center justify-center w-[100vw] md:mt-10 mb-20" >
                    <GooglePlayButton
                        onClick={() => userEventsListenersGTAG({
                            action: "Download Android",
                            category: "download",
                            label: "download",
                            value: android.current,
                        })}
                        url={android.current}
                        theme={"dark"}
                        className={"custom-style !w-[300px] !h-[80px] md:mt-0 mt-5"}
                    />

                    <AppStoreButton
                        onClick={() => userEventsListenersGTAG({
                            action: "Download IOS",
                            category: "download",
                            label: "download",
                            value: ios.current,
                        })}
                        url={ios.current}
                        theme={"light"}
                        className={"custom-style !w-[300px] !h-[80px] mt-5 md:mt-0 ml-0 md:ml-5"}
                    />
                </div> : <div className='flex flex-col w-[100%] justify-center py-10'><Spin size='50' /></div>
            }
        </div >
    )
}
