// const isProduction = process.env.REACT_APP_ENV === 'production';
// const isLocal = process.env.REACT_APP_ENV === 'local'; // Check if running locally

// let PRODUCTION_CONFIG = {};

// // Do not define if not production to prevent secret_key not defined error
// if (isProduction) {
//     PRODUCTION_CONFIG = {
//         domain: 'https://beemi.app/',
//         backendUrl: 'https://us-central1-beemi-web.cloudfunctions.net',
//         database: 'web-prod',
//         gift: 'fetchStreams',
//         sendGiftChapaApi: '/giftStreamer',
//         stripBackendPath: '/stripePayment',
//         stripeAccessKey: 'pk_live_51QFYz8RNRdH2UraMeYiY093NQ0PcidOsusD30SEqj9l8dBMyFWuJ3OlPOhPSUoQgRH1BdbkkpTyLFNdr5SdLMzgS00Z0fWtVU1'
//     };
// }

// const STAGING_CONFIG = {
//     domain: 'https://beemi-web--livetest-nlzcgshd.web.app',
//     backendUrl: 'https://us-central1-beemi-web.cloudfunctions.net',
//     database: 'web-stg',
//     gift: 'fetchStreamsTest',
//     sendGiftChapaApi: '/giftStreamerTest',
//     stripBackendPath: '/stripePaymentTest',
//     stripeAccessKey: 'pk_test_51QFYz8RNRdH2UraMLibKgCCmPwC3fdH1HhTvEEyniSAhFCTLctZO7mDkeDewbFxpV2GXzd2AdgpldjxCCHSmw9oh00Kd19Js6L'
// };

// const LOCAL_CONFIG = {
//     domain: 'http://localhost:3000', // Adjust based on your local setup
//     backendUrl: 'http://127.0.0.1:5002/beemi-web/us-central1'
// };

// const ACTIVE_CONFIG = isProduction ? PRODUCTION_CONFIG : isLocal ? LOCAL_CONFIG : STAGING_CONFIG;

// if (isLocal)
//     for (const key in ACTIVE_CONFIG)
//         ACTIVE_CONFIG[key] = LOCAL_CONFIG[key];

// export const CONFIG = {
//     ...ACTIVE_CONFIG,
//     paths: {
//         success: '/done/{channel}?session_id={CHECKOUT_SESSION_ID}',
//         cancel: '/cancel',
//     },
//     apiKey: 'SN1770OD5N0IUAH4JNN5AWUQOE48FC'
// };

export const CONFIG = {
    domain: process.env.REACT_APP_DOMAIN,
    backendUrl: process.env.REACT_APP_FIREBASE_BACKEND,
    database: process.env.REACT_APP_FIRESTORE_DATABASE_ID,
    stripeBackendUrl: process.env.REACT_APP_STRIPE_BACKEND_URL,
    stripeAccessKey: process.env.REACT_APP_STRIPE_ACCESS_KEY,
    paths: {
        success: '/done/{channel}?session_id={CHECKOUT_SESSION_ID}',
        cancel: '/cancel',
    },
    apiKey: 'SN1770OD5N0IUAH4JNN5AWUQOE48FC'
}
