import React, { useEffect } from 'react';
import giftIcon from '../../../images/gift.svg';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'antd';
import useHandleSubmit from '../../customHook/useProcessPaymentHook';
import { fetchIpAddress } from '../../utils/utilFunctions';






export function GiftList() {
    const [usernameInput, setUsernameInput] = React.useState('');
    let [payingWith, setPayingWith] = React.useState('');
    const location = useLocation();
    let { user, gift } = location.state || {};
    const [gifts, setGifts] = React.useState(gift);
    const [userData, setUserData] = React.useState(user);
    const anonymous = React.useRef(false)
    const [currency, setCurrency] = React.useState('');
    const { handleSubmit, isLoading, errorWeb } = useHandleSubmit(userData, gifts, usernameInput, location, payingWith);



    const handleInputChange = (e) => {
        setUsernameInput(e.target.value);
    };


    useEffect(() => {
        setGifts(gift)
    }, [])

    const radioButtonChange = (e) => {
        anonymous.current = e.target.checked;
        if (!e.target.checked) {
            setUsernameInput('')
        } else {
            setUsernameInput('Anonymous')
        }
    }


    const setPaymentBasedOnLocation = async () => {
        const locatios = await fetchIpAddress()
        if (locatios.country.toLocaleLowerCase() == 'et') {
            setPayingWith('chapa')
            setCurrency('et')
        }
        else {
            setPayingWith('stripe')
            setCurrency('default')
        }
    }

    useEffect(() => {
        setPaymentBasedOnLocation()
    }, [])


    useEffect(() => { }, [usernameInput])

    return (
        <div className='flex flex-row justify-center items-center w-[100vw] h-[auto] bg-white py-3 md:py-0 md:mt-12'>
            <div className='flex flex-col min-h-[100vh] w-[100vw] justify-center items-center pt-32 md:pt-0'>
                <div className={`flex flex-col justify-between items-center !z-30 absolute md:top-[15%] top-32`}>
                    <img src={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} alt='User' className='w-[100px] h-[100px] md:w-[180px] md:h-[180px] rounded-full z-20 bg-white border-[8px] border-[#e3caca]' />
                    <br />
                </div>
                <div className='relative flex flex-col w-[80vw] h-[76vh] mt-24 px-5 justify-center items-center'>
                    <div>
                        <p className='text-[#FF467E] text-2xl font-[Montserrat-Bold] my-0 pt-10 '>{userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}</p>

                    </div>
                    <div className='flex flex-col md:flex-row justify-around items-center bg-gradient-to-r from-[#F99F20] pb-10 to-[#F34893] rounded-lg w-[65vw] md:h-[35vh] md:px-10' >
                        <div className='flex flex-col justify-end px-0  md:w-[20vw] h-[25vh]  w-[80%] md:mb-0 mb-8 overflow-hidden  md:mt-5 mt-10 rounded-md relative shadow-xl '>
                            <img src={gifts.display?.ImageUrl} className='w-40 h-40 rounded-full rotate-12 absolute top-[0%] ml-14' />
                            <div className='flex flex-col justify-end items-left bg-[white] px-2 pt-10 '>
                                <span className='text-[#F88259] font-[Montserrat-Bold] text-2xl'>{gifts.display.Name}</span>
                                <span className='text-gray-700 font-[Montserrat-Bold] text-2xl'>{gifts.display.price[currency]}</span>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center w-[100%] pt-10'>
                            <input
                                type='text'
                                placeholder='Enter your username'
                                required
                                className='border-0 border-gray-300  p-2 mb-4 w-[90%] outline-none rounded-full text-center font-[Montserrat-Bold]'
                                value={usernameInput}
                                onChange={handleInputChange}
                                disabled={anonymous.current}
                            />
                            <div className='flex items-left justify-center'>
                                <Checkbox className='text-white' onChange={radioButtonChange}>Send Anonymously</Checkbox>
                            </div>
                        </div>
                    </div>
                    <span className='font-[Montserrat-Bold]  text-[red]'>{errorWeb}</span>
                    <div className="flex justify-center bg-gradient-to-r from-yellow-500 to-[#F2419C] text-white shadow-md shadow-black mr-2 px-8 mb-2 rounded-md mt-5">
                        <a onClick={handleSubmit} className="font-[Montserrat-Bold] cursor-pointer text-gradient-to-r from-[#F99F20] to-[#F34893] text-white rounded flex items-center py-1">
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                <img src={giftIcon} alt="Gift" className="w-10 h-10" />
                            )}
                            {isLoading ? `Processing with ${payingWith}...` : 'Send Gift'}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
