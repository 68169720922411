import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Featured() {
    const { t } = useTranslation();

    return (
        <div className="flex md:flex-row justify-between  px-5 md:px-20 md:pt-20  py-10 items-center flex-col ">
            <div className="text-white font-[Montserrat-Bold]">
                <div className="text-xl md:text-4xl font-[Montserrat-Bold] text-center overflow-hidden" >
                    {t('audiance')}
                </div>
                <div className="flex flex-row flex-wrap items-center justify-around">
                    <div className="flex flex-col justify-center items-center w-[30%]">
                        <img className='w-32 md:w-[300px]' src={require('../../images/play-icon.webp')} />
                        <div className="text-xl md:text-4xl font-[Montserrat-Bold] overflow-hidden md:pt-5">
                            {t('play')}
                        </div>
                        <div className="text-center max-w-[100%] min-w-[100%] text-2xl font-[Montserrat-Regular] invisible md:visible h-0 md:h-auto">
                            {t('playbody')}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center w-[30%]">
                        <img className='w-32 md:w-[300px]' src={require('../../images/battle-icon.webp')} />
                        <div className="text-xl md:text-4xl font-[Montserrat-Bold] overflow-hidden md:pt-5">
                            {t('battle')}
                        </div>
                        <div className="text-center  max-w-[100%] min-w-[100%] text-2xl font-[Montserrat-Regular] invisible md:visible h-0 md:h-auto">
                            {t('battlebody')}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center w-[30%] md:pt-8">
                        <img className='w-[300px]' src={require('../../images/host-icon.webp')} />
                        <div className="text-xl md:text-4xl font-[Montserrat-Bold] overflow-hidden">
                            {t('host')}
                        </div>
                        <div className="text-center max-w-[80%] min-w-[80%] text-2xl font-[Montserrat-Regular] invisible md:visible h-0 md:h-auto ">
                            {t('hostbody')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
