

// import Stripe from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CONFIG } from './CONFIG';

// Load Stripe.js
const stripePromise = loadStripe(CONFIG.stripeAccessKey); // Replace with your Stripe public key

// Helper function to call the backend API
async function callBackend(endpoint, data) {
    const response = await fetch(`${endpoint}`, { // Use BACKEND_DOMAIN here
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to call backend API');
    }

    return response.json();
}

// Function to handle product selection and initiate payment
/**
 * Initiates a Stripe Checkout session for a given product.
 * 
 * @param {string} productId - The ID of the product to purchase.
 * @param {string} userId - The ID of the user making the purchase.
 * @param {string} channel - The channel where the purchase is being made.
 * @param {Object} giftData - Additional gift data for the purchase.
 * 
 * Example call:
 * purchaseProduct(
 *   'prod_12345',
 *   'user_67890',
 *   'channel_example',
 *   {
 *       GiftId: 'gift_001',
 *       GifterId: '@exampleGifter',
 *       GifterName: 'John Doe',
 *       GifterImageUrl: 'https://example.com/image.jpg'
 *   }
 * );
 */
export async function purchaseProduct(productId, userId, channel, giftData) {
    try {
        console.log('Initiating payment for product:', productId);

        // Step 1: Call backend to create a Checkout Session
        const { sessionId } = await callBackend(`${CONFIG.stripeBackendUrl}/create-checkout-session`, {
            productId,
            userId,
            channel,
            giftData,
        });

        console.log('Checkout session created. Redirecting to Stripe Checkout:', sessionId);

        // Step 2: Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({ sessionId });

        if (result.error) {
            console.error('Error redirecting to Checkout:', result.error.message);
        }
    } catch (error) {
        console.error('Error processing product purchase:', error.message);
    }
}