import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import gift from '../../images/gift.svg';
import giftColor from '../../images/gift-color.svg';
import Error from './components/Error';
import Loading from './components/Loading';
import { CONFIG } from '../../components/utils/CONFIG';


const BeemiLive = () => {
    const [visibleCount, setVisibleCount] = useState(8);
    const [streamer, setStreamer] = useState([]);
    const [home, setHome] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const currentRoute = location.pathname;
    
    

    useEffect(() => {
        const fetchStreams = async () => {
            const raw = JSON.stringify({ "allStreamers": false });
            const requestOptions = {
                method: "POST",
                body: raw,
                redirect: "follow"
            };

            try {
                const response = await fetch(`${CONFIG.backendUrl}/fetchStreams`, requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                let newFilter = result.filter((stream) => stream.isLive !== false);
                setStreamer(newFilter);

                setStreamer(result)
                setLoading(true)
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchStreams();
    }, []);

    const handleNavigate = (user) => {
        navigate(`/gift/${user.tiktokChannel}`, { state: { user } });
    };

    useEffect(() => {
        setHome(currentRoute === '/gifts');
    }, [currentRoute]);

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <Error error={error} />;
    }

    return (
        <div className={`w-[100vw] p-4 bg-gradient-to-r min-h-[100vh] from-[#F99F20] to-[#F34893] py-12 ${home ? ' pt-[15vh]' : 'md:pt-32'}`}>
            <div className=' md:h-[10vh] flex md:flex-row flex-col justify-center items-center md:rounded-md shadow-xl mb-10 md:mx-[15%] mx-2'>
                <img src={giftColor} alt="Gift" className="w-14 h-14" />
                <p className='text-center text-[white]  font-[Montserrat-Bold] md:text-2xl uppercase'>
                    support your favourite creators
                </p>
            </div>
            {streamer?.length === 0 && <p className='font-[Montserrat-Regular] text-2xl text-white uppercase text-center'>No Live Streamers</p>}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 md:gap-4 md:mx-[15vw]">
                {streamer.slice(0, home ? streamer?.length : visibleCount).map((user, index) => (
                    <div key={user.id} className="bg-white p-4 rounded-md md:rounded-[20%] shadow-md shadow-black m-2 w-[95%] md:w-[79%] min-w-[90%] h-[85%] flex flex-col items-center overflow-hidden">
                        <img src={user.profilePicture ? user.profilePicture : 'https://i.pravatar.cc/150?img=1'} alt={`Avatar ${index}`} className="w-32 h-32 rounded-full mb-4 border-[5px] border-transparent bg-gradient-to-r from-[#F56967] to-pink-500 p-[3px]" />
                        <p className="text-lg max-w-[80%] font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-[#F2419C]">{user.tiktokChannel ? user.tiktokChannel : "XXXXX"}</p>
                        <div className="flex justify-center bg-gradient-to-r from-yellow-500 to-[#F2419C] rounded-full text-white py-1 px-2 shadow-md shadow-black font-[Montserrat-Bold] overflow-hidden">
                            <a onClick={() => handleNavigate(user)} className="font-[Montserrat-Bold] cursor-pointer text-gradient-to-r from-[#F99F20] to-[#F34893] text-white py-2 px-4 rounded flex items-center">
                                <img src={gift} alt="Gift" className="w-10 h-10" />
                                Send Gift
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            {!home && visibleCount < streamer.length && (
                <div className="flex justify-center mt-4">
                    <a href='/gifts' className="font-[Montserrat-Bold] cursor-pointer text-gradient-to-r from-[#F99F20] to-[#F34893] text-white py-2 px-4 rounded">See More</a>
                </div>
            )}
        </div>
    );
};

export default BeemiLive;
