import axios from 'axios';
import { load } from 'cheerio';


async function fetchIpAddress() {
  try {
    const response = await axios.get('https://ipinfo.io/what-is-my-ip');
    const html = response.data;
    const $ = load(html);
    const ipAddress = $('.h1-max').text();
    console.log('IP address:', html.country);

    return html;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
}



export { fetchIpAddress };