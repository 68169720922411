import React from 'react'
import profit from '../../images/profit.webp'
import { useTranslation } from 'react-i18next';



export default function ProfitHoder() {
    const { t, i18n } = useTranslation();

    return (
        <div  className="flex md:flex-row flex-col  justify-between items-center px-[9vw] -z-50 -mt-[14vh]  md:-mt-[18vh] w-[100vw] overflow-hidden bg-wave-pattern">
            <div >
                <img src={profit} />
            </div>
            <div className=" text-xl pb-5 md:pb-0 md:text-4xl max-w-[80%] text-center md:text-left md:max-w-[50%] text-[#3B41D8] font-[Montserrat-Bold] overflow-hidden">
                {t('growth')}
            </div>
        </div>
    )
}
