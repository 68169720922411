import React from 'react';

export default function Loading() {
    return (
        <div className="flex justify-center items-center h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
            <div className="text-center flex flex-col items-center overflow-hidden">
                <div className="relative w-24 h-24 overflow-hidden">
                    <svg className="absolute top-0 left-0 w-full h-full animate-spin-slow" viewBox="0 0 100 100">
                        <circle
                            className="text-gray-200"
                            strokeWidth="10"
                            stroke="currentColor"
                            fill="transparent"
                            r="45"
                            cx="50"
                            cy="50"
                        />
                        <circle
                            className="text-[#F99F20]"
                            strokeWidth="10"
                            strokeDasharray="283"
                            strokeDashoffset="75"
                            strokeLinecap="round"
                            stroke="currentColor"
                            fill="transparent"
                            r="45"
                            cx="50"
                            cy="50"
                        />
                    </svg>
                </div>
            </div>
            <style>
                {`
            @keyframes spin-slow {
                0% {
                transform: rotate(0deg);
                }
                100% {
                transform: rotate(360deg);
                }
            }
            .animate-spin-slow {
                animation: spin-slow 1.5s linear infinite;
            }
            `}
            </style>
        </div>
    );
}
