import React from 'react'
import './App.css';
import Home from "./components/pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/pages/About";// Import the JSON data
import Contact from './components/pages/Contact';
import LayoutMain from './components/LayoutMain';
import Referral from './Referral';
import logo from './Referral/assets/beemi.svg'
import ReferalHome from './Referral';
import BeemiLive from './components/BeemiLive';
import MoreGifts from './components/BeemiLive/components/OnGift';
import Great from './components/BeemiLive/components/Great';
import MGifts from './components/MGifts';
import { GiftList } from './components/BeemiLive/components/GiftDetial';



const SimpleLayout = ({ children }) => {
  return (
    <div className="h-[100vh]">
      <header className="w-full h-16 sm:h-24 flex justify-center items-center " >
        <img className="h-14 sm:h-24 -ml-6" src={logo} alt="logo" />
      </header>
      <ReferalHome />
    </div>
  );
}
const ErrorPage = () => {
  return (
    <div className="h-[100vh] flex flex-col justify-center items-center">
      <img className="h-20 sm:h-24 mb-0 -ml-20" src={logo} alt="logo" />
      <h1 className="text-2xl sm:text-4xl mb-4 font-[Montserrat-Bold] uppercase">Page Not Found</h1>
      <a 
        className="px-4 py-2 bg-gradient-to-r from-yellow-500 to-[#F2419C] text-white rounded" 
        href='/'
      >
        Go Back
      </a>
    </div>
  );
};


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutMain />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gifts" element={<BeemiLive />} />
          <Route path="/gift/:username" element={<MoreGifts />} />
          <Route path='/gift/:username/:giftId' element={<GiftList />} />
          <Route path='/done/:channel' element={<Great />} />
          <Route exact path="/my-gifts" element={<MGifts />} />
        </Route>
        <Route element={<SimpleLayout />}>
          {/* <Route path="/referral" element={<Referral />} /> */}
          <Route path="/:username" element={<Referral />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );


}

export default App;
