import React from 'react'
import logo from "../images/bee-logo.svg";
import name from '../images/bee-name.svg'
import { useTranslation } from 'react-i18next';
import { DownOutlined, SmileOutlined, MenuFoldOutlined } from '@ant-design/icons';
import one from '../images/telegram.svg'
import { Dropdown, Space, Drawer } from 'antd';
import english from '../images/language.svg'
import amharic from '../images/ethiopia.png'



export default function Header() {
    const { t, i18n } = useTranslation();
    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
    };
    const [open, setOpen] = React.useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const items = [
        {
            key: '1',
            label: (
                <a onClick={() => handleLanguageChange("en")} >
                    english
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => handleLanguageChange("am")} >
                    amharic
                </a>
            ),
        },

    ];
    const languages = { en: 'English', enIm: english, am: 'Amharic', amIm: amharic }

    const isWebView = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream));
    };

    const isNotRootUrl = () => {
        return window.location.pathname !== '/';
    };

    React.useEffect(() => {
        if (isWebView() && isNotRootUrl()) {
            document.querySelector('#header').style.display = 'none';
        }
    }, []);

    return (
        <div className="header shadow-lg " id="header">
            <a href="/" className="header-logo-container">
                <img src={logo} className="header-logo" />
                <img src={name} className="header-name md:-ml-10" />
            </a>
            {/* <a onClick={showDrawer} className='w-10 h-10  md:invisible absolute right-10 '>
                <MenuFoldOutlined style={{ fontSize: 30 }} />
            </a> */}
            <div className="md:w-[20%] flex flex-row justify-between md:mr-10 items-center">
                <a href="#download" className="font-[Montserrat-Bold] mb-2 md:text-2xl ml-10 md:ml-0  bg-[#8d0ff5] text-white px-2 py-1 md:px-8 md:py-4 md:mr-7 mr-2 rounded-[100px]   md:w-auto shadow-md shadow-[#2f2f2f]">{t('download')} </a>
                <div className='bg-[white]  w-[100px]  rounded-md flex text-center flex-row justify-center items-center text-white font-[Montserrat-Bold] mr-2'>
                    <Dropdown
                        menu={{
                            items,
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <img src={languages[`${i18n.language}Im`]} className='w-5' />
                                <h1 className='!font-[Montserrat-Bold] text-[#8d0ff5]'><DownOutlined className='font-extrabold' /></h1>
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
            {/* <Drawer title="" className='!bg-transparent' onClose={onClose} open={open}>
                <div className=" flex flex-col justify-center items-center  md:flex-row md:justify-between md:mr-10 items-cente  jus w-[100%] h-[80vh] ">
                    <a href="#download" className="font-[Montserrat-Bold] mb-2 text-2xl ml-[10%] bg-[#8d0ff5] text-white px-8 py-4 mr-7 rounded-[100px]   md:w-auto shadow-md shadow-[#2f2f2f]">{t('download')} </a>
                    <br /><a href='https://t.me/DawitAbraham' className='bg-[#F0F0F0] px-2 flex flex-row items-center justify-between py-1 rounded-[10px] font-[Montserrat-Bold] shadow-md shadow-[#2f2f2f]'>
                        <img src={one} className='w-5 h-5 ml-2' />
                        <a className='px-2'>
                            <p className='text-xs text-[#8035BC] font-[Montserrat-Bold]'>{t('contactus')}</p>
                            <p className='font-[Montserrat-Bold] text-[#8d0ff5]' >+251910184144</p>
                        </a>
                    </a>
                    <br />
                    <div className='bg-[#F0F0F0] mt-10 w-[130px] h-10 rounded-md flex text-center flex-row justify-center items-center text-white font-[Montserrat-Bold] shadow-md shadow-[#2f2f2f] mr-2'>
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <img src={languages[`${i18n.language}Im`]} className='w-5' />
                                    <h1 className='!font-[Montserrat-Bold] text-[#8d0ff5]'>{languages[i18n.language]} <DownOutlined className='font-extrabold' /></h1>
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </Drawer> */}
        </div>
    )
}
