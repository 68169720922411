import React from 'react'
import play from '../../images/play.svg'
import imgPhone from '../../images/phone.png'
// import one from '../images/.
import one from '../../images/telegram.svg'

import imgOne from '../../images/laptop.png'
import { useParallax } from 'react-scroll-parallax';
import pices from '../../images/torus-2.webp'
import news from '../../images/newHeroRight.svg'
import { useTranslation } from 'react-i18next'



const HeroElement = () => {
    return (
        <div className="w-[100vw] md:w-[50%] md:p-20">
            <img src={news} className="absolute w-[40%] md:w-[20vw] md:left-[57vw] left-16 top-[15vh] md:top-[25vh] z-50" />
            <img src={news} className="absolute w-[55%] top-[15vh] md:w-[30vw] right-[6vw] md:top-[20vh] rotate-[25deg]" />
        </div>
    )
}
const Explore = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="w-[100%]   flex flex-col px-5 md:p-20 mt-[110vw] md:mt-0">
            <p className="text-xl md:text-4xl text-white font-[Montserrat-Bold] md:text-left text-center md:max-w-[80%]">
                {t('engage')}</p>
            <div className="flex md:flex-row flex-col justify-center md:justify-around items-center md:pt-10 w-[100%] md:w-[50vw]">
                <a href='https://t.me/DawitAbraham' className='bg-[#F0F0F0] px-10 flex flex-row items-center justify-between py-3 mt-5 md:mt-0 w-[200px] md:w-[200px] rounded-[10px] font-[Montserrat-Bold] shadow-md shadow-[#2f2f2f]'>
                    <img src={one} className='w-5 h-5' />
                        <p className='text-md text-[#8035BC] font-[Montserrat-Bold] w-[200px]'>{t('contactus')}</p>
                </a>
                {/* <a href="#download" className='bg-[#8d0ff5] px-10 py-2 md:px-10 md:py-5 font-[Montserrat-Bold] md:text-2xl text-white rounded-[200px] shadow-md shadow-[#2f2f2f] my-5'>{t('download')}</a> */}
                <a className="flex flex-row justify-center md:justify-center items-center pt-10 md:pt-0 w-[200px] md:w-[100vw] md:-ml-32 ">
                    <img src={play} className='w-[30px] h-[30px] ' />
                    <a href="#how" className="text-sm font-[Montserrat-Bold] ml-0 md:ml-0 text-white ">{t('seehowitworks')}</a>
                </a>
            </div>
        </div>
    )
}


export default function Hero() {

    const pice = useParallax({
        translateY: [-500, 300],
    });
    return (
        <div className="min-h-[100vh] px-[5vw] flex md:flex-row md:justify-around flex-col  xl:max-w-[1800px] md:items-center" >
            <img className="w-[15vw] absolute left-0 -ml-[5vw] -z-10" ref={pice.ref} src={pices} />
            <Explore />
            <HeroElement />
        </div>
    )
}
